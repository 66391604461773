import React from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import logo from "./Workiz_Logox500.png"

const App = ()=>{
  return (
    <div>
      <div className="swagger-ui" style={
        {
          top:"0",
          width:"100%",
          height:"95px",
          backgroundColor:"#FFF",
          zIndex:"99",
          boxShadow:"0 2px 4px 0 rgba(0, 0, 0, 0.3)",
          display : "flex",
        }
      }>
        <div className="wrapper" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <img src={logo} width="150" style={{objectFit: "contain"}}/>
          <h1 className="_titleMed">Developer API</h1>
          <a className="_sign" href="https://app.workiz.com/sign/">Free Trial</a>
        </div>
      
      </div>
      <SwaggerUI url="/api.json" docExpansion='list'/>
    </div>
  )
}

export default App;
